import React from "react";
import Return from "../components/RefundAndReturn/Return";

const ReturnPolicy = () => {
  return (
    <div>
      <Return />
    </div>
  );
};

export default ReturnPolicy;
