export const AccordianData = [
  {
    faqHeading: "Shipping Information",
    accordianID: "accordion-1",
    headerID: "heading-1",
    href: "#collapse-1",
    ariaControls: "collapse-1",
    question: "How will my parcel be delivered?",
    answerID: "collapse-1",
    aria: "heading-1",
    data: "#accordion-1",
    answer:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim pellentesque felis. Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula vulputate sem tristique cursus. Nam nulla quam, gravida non, commodo a, sodales sit amet, nisi.",
  },
  {
    faqHeading: "Shipping Information",
    accordianID: "accordion-1",
    headerID: "heading-2",
    href: "#collapse-2",
    ariaControls: "collapse-2",
    question: "Do I pay for delivery?",
    answerID: "collapse-2",
    aria: "heading-2",
    data: "#accordion-2",
    answer:
      "Ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum.Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros.",
  },
  {
    faqHeading: "Shipping Information",
    accordianID: "accordion-1",
    headerID: "heading-3",
    href: "#collapse-3",
    ariaControls: "collapse-3",
    question: "Will I be charged customs fees?",
    answerID: "collapse-3",
    aria: "heading-3",
    data: "#accordion-3",
    answer:
      "Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum.Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. ",
  },
  //   {
  //     faqHeading: "Shipping Information",
  //     accordianID: "accordion-1",
  //     headerID: "heading-4",
  //     href: "#collapse-5",
  //     ariaControls: "collapse-4",
  //     question: "My item has become faulty",
  //     answerID: "collapse-4",
  //     aria: "heading-4",
  //     data: "#accordion-4",
  //     answer:
  //       "Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum.Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. ",
  //   },
  {
    faqHeading: "Orders and Returns",
    accordianID: "accordion-2",
    headerID: "heading2-1",
    href: "#collapse2-1",
    ariaControls: "collapse2-1",
    question: "Tracking my order",
    answerID: "collapse2-1",
    aria: "heading2-1",
    data: "#accordion-2",
    answer:
      " Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum.",
  },
  {
    faqHeading: "Orders and Returns",
    accordianID: "accordion-2",
    headerID: "heading2-2",
    href: "#collapse2-2",
    ariaControls: "collapse2-2",
    question: "I haven’t received my order",
    answerID: "collapse2-2",
    aria: "heading2-2",
    data: "#accordion-2",
    answer:
      " Ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum.Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros.",
  },
  {
    faqHeading: "Orders and Returns",
    accordianID: "accordion-2",
    headerID: "heading2-3",
    href: "#collapse2-3",
    ariaControls: "collapse2-3",
    question: "How can I return an item?",
    answerID: "collapse2-3",
    aria: "heading2-3",
    data: "#accordion-2",
    answer:
      "Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum.Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. ",
  },
  {
    faqHeading: "Payments",
    accordianID: "accordion-3",
    headerID: "heading3-1",
    href: "#collapse3-1",
    ariaControls: "collapse3-1",
    question: "What payment types can I use?",
    answerID: "collapse3-1",
    aria: "heading3-1",
    data: "#accordion3",
    answer:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum.",
  },
  {
    faqHeading: "Payments",
    accordianID: "accordion-3",
    headerID: "heading3-2",
    href: "#collapse3-2",
    ariaControls: "collapse3-2",
    question: "Can I pay by Gift Card?",
    answerID: "collapse3-2",
    aria: "heading3-2",
    data: "#accordion3",
    answer:
      "Ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum.Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros.",
  },
  {
    faqHeading: "Payments",
    accordianID: "accordion-3",
    headerID: "heading3-3",
    href: "#collapse3-3",
    ariaControls: "collapse3-3",
    question: "I can't make a payment",
    answerID: "collapse3-3",
    aria: "heading3-3",
    data: "#accordion3",
    answer:
      "Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum.Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros.",
  },
  {
    faqHeading: "Payments",
    accordianID: "accordion-3",
    headerID: "heading3-4",
    href: "#collapse3-4",
    ariaControls: "collapse3-4",
    question: "Has my payment gone through?",
    answerID: "collapse3-4",
    aria: "heading3-4",
    data: "#accordion3",
    answer:
      "Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum.Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros.",
  },
];
