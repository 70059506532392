import React from "react";
import EditBilling from "../components/Account/EditBilling";

const EditBillingDetails = () => {
  return (
    <div>
      <EditBilling />
    </div>
  );
};

export default EditBillingDetails;
