import React from "react";
import EmailVerification from "../components/PasswordResetPage/EmailVerification";

const Verification = () => {
  return (
    <div>
      <EmailVerification />
    </div>
  );
};

export default Verification;
