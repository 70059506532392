import React from "react";
import ChangePassword from "../components/PasswordResetPage/ChangePassword";

const NewPassword = () => {
  return (
    <div>
      <ChangePassword />
    </div>
  );
};

export default NewPassword;
