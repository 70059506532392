import React from "react";
import Refund from "../components/RefundAndReturn/Refund";

const RefundPolicy = () => {
  return (
    <div>
      <Refund />
    </div>
  );
};

export default RefundPolicy;
