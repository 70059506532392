import React from "react";
import SingleProduct from "../components/SingleProduct/SingleProduct";

const Product = () => {
  return (
    <div>
      <SingleProduct />
    </div>
  );
};

export default Product;
