import React from "react";
import Accounts from "../components/Account/Accounts";

const MyAccount = () => {
  return (
    <div>
      <Accounts />
    </div>
  );
};

export default MyAccount;
