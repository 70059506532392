import React from "react";
import Question from "../components/Faq/Question";

const Faq = () => {
  return (
    <div>
      <Question />
    </div>
  );
};

export default Faq;
